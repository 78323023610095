@use "uswds-core" as *;
@use "~@massds/mayflower-assets/scss/00-base/configure" as massds;

.c-beta-banner {
  @include massds.ma-container;
  text-align: center;

  @media (min-width: units("tablet")) {
    text-align: left;
  }
}
