.c-unsupported-browser-banner {
  display: none;
}

// Target IE11 and below
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-unsupported-browser-banner {
    display: block;
  }
}
