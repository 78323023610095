.c-thumbnail {
  flex-shrink: 0;
}

.c-thumbnail img {
  max-height: 100px;
}

.c-thumbnail--fallback {
  align-items: center;
  height: 100px;
  justify-content: center;
  padding-top: 0;
  width: 100px;
}
