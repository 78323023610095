@use "uswds-core" as *;

// Global layout styles, primarily responsible for sticking
// the footer to the bottom of the screen, and overriding
// some aspects of the U.S. Web Design System that can't
// be configured through a Sass variable.
.l-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-main {
  flex-grow: 1;
  width: 100%;
}

.usa-form {
  // USWDS defaults their form width to be very narrow,
  // which doesn't work with our larger type styles
  max-width: units("tablet-lg");
}

strong {
  // Fixes issue where this was being set to "stronger" which
  // was heavier than the weight we want
  font-weight: $theme-font-weight-bold;
}
