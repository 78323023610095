@use "uswds-core" as *;

// Override Mayflower Pagination component styles
.ma__pagination {
  // Prevent double border when rendered below a table
  .ma__pagination__container {
    border-top: 0;
    padding: 0;
  }

  a {
    color: $theme-color-primary;
    font-weight: normal;
    text-decoration: none;
  }
}
