@use "uswds-core" as *;

// On sizes smaller than 'tablet' the circular indicators disappear per USWDS CSS,
// this media query makes the custom styling respect that.
$action-required-color: #f6c51b;
$cancelled-color: $theme-color-error;
$not-started-color: $theme-color-base;

@media (min-width: 640px) {
  .usa-step-indicator--ma_pfml_icons_and_states
    .usa-step-indicator__segment--complete:before {
    background-color: $theme-color-secondary;
    content: url("/img/check-white.svg");
  }

  .usa-step-indicator--ma_pfml_icons_and_states
    .usa-step-indicator__segment--current:before {
    background-color: $theme-color-primary;
    content: url("/img/autorenew-white.svg");
  }

  .usa-step-indicator--ma_pfml_icons_and_states
    .usa-step-indicator__segment--notComplete:before {
    background-color: $not-started-color;
    // There's no way to override the color without redefining the whole box shadow
    -webkit-box-shadow: inset 0 0 0 0.3rem $not-started-color, 0 0 0 0.3rem #fff;
    box-shadow: inset 0 0 0 0.3rem $not-started-color, 0 0 0 0.3rem #fff;
    content: url("/img/arrow_forward-white.svg");
  }

  // USWDS has three states included in the StepIndicator, we need at least four.
  // This state is -not- included in the USWDS styles so it needs overrides to set
  // the color of the bubbles and bars. For the three included statues this is
  // handled via theme styling in _uswds-theme.scss.
  .usa-step-indicator--ma_pfml_icons_and_states
    .usa-step-indicator__segment--actionRequired:before {
    background-color: $action-required-color;
    // There's no way to override the color without redefining the whole box shadow
    -webkit-box-shadow: inset 0 0 0 0.3rem $action-required-color,
      0 0 0 0.3rem #fff;
    box-shadow: inset 0 0 0 0.3rem $action-required-color, 0 0 0 0.3rem #fff;
    // This sets the icon like the other states above
    content: url("/img/warning-black.svg");
  }

  .usa-step-indicator--ma_pfml_icons_and_states
    .usa-step-indicator__segment--cancelled:before {
    background-color: $cancelled-color;
    // There's no way to override the color without redefining the whole box shadow
    -webkit-box-shadow: inset 0 0 0 0.3rem $cancelled-color, 0 0 0 0.3rem #fff;
    box-shadow: inset 0 0 0 0.3rem $cancelled-color, 0 0 0 0.3rem #fff;
    // This sets the icon like the other states above
    content: url("/img/close-white-alt-larger.svg");
  }
}

// Set the color of the bar between the bubbles, the bar should stay this color at all
// screen sizes so it's outside the media query
.usa-step-indicator--ma_pfml_icons_and_states
  .usa-step-indicator__segment--actionRequired:after {
  background-color: $action-required-color;
}
.usa-step-indicator--ma_pfml_icons_and_states
  .usa-step-indicator__segment--cancelled:after {
  background-color: $cancelled-color;
}

.usa-step-indicator--ma_pfml_icons_and_states
  .usa-step-indicator__segment--notComplete:after {
  background-color: $not-started-color;
}

// Set the color of the text below the bubbles. Unfortunately USWDS doesn't provide
// the ability to set these.
.usa-step-indicator__segment--current {
  .usa-step-indicator__segment-label {
    color: $theme-color-base-ink;
  }
}

.usa-step-indicator__segment--actionRequired {
  .usa-step-indicator__segment-label {
    color: $theme-color-base-ink;
    font-weight: 550;
  }
}

.usa-step-indicator__segment--complete {
  .usa-step-indicator__segment-label {
    color: $theme-color-base-ink;
  }
}

.usa-step-indicator__segment--notComplete {
  .usa-step-indicator__segment-label {
    color: $theme-color-base-ink;
  }
}

// These styles are used by HeaderDisplayItem, but they build on the step indicator
// styles, so keeping them in this file.

.actionRequiredIcon {
  content: url("/img/warning-black.svg");
}
.actionRequiredIcon-background-color {
  background-color: $action-required-color;
}

.inProgressIcon {
  content: url("/img/autorenew-white.svg");
}
.inProgressIcon-background-color {
  background-color: $theme-color-primary;
}

.completedIcon {
  content: url("/img/check-white.svg");
}
.completedIcon-background-color {
  background-color: $theme-color-secondary;
}

.notStartedIcon {
  content: url("/img/arrow_forward-white.svg");
}
.notStartedIcon-background-color {
  background-color: $not-started-color;
}

@include at-media-max("tablet") {
  .hide-on-mobile {
    display: none;
  }
}
