@use "uswds-core" as *;

.usa-language-container {
  ul.usa-language__submenu {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    position: absolute;
    background-color: $theme-color-primary;
    z-index: 2;
    border: 2px solid #f2f1f2;

    li.usa-language__submenu-item {
      text-align: left;
      font-size: 0.93rem;

      &:not(:first-of-type) {
        border-top: 1px solid #dfe1e2;
      }

      button {
        display: block;
        padding: 16px;
      }
    }
  }

  // Allows for the dropdown to be positioned relative to this container on all screen sizes
  // and the outer container on mobile
  @media (min-width: 411px) {
    .usa-language__primary-item {
      position: relative;
    }
    ul.usa-language__submenu {
      width: 12rem;
      right: 16px;
      border: none;
    }
  }

  .usa-language__submenu button {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
