@use "uswds-core" as *;

// USWDS doesn't currently have Sass variables for theming the Alert icon colors
.usa-alert--error::before {
  background-color: $theme-color-error;
}

.usa-alert--info::before {
  background-color: $theme-color-info;
}

.usa-alert--success::before {
  background-color: $theme-color-success;
}

.usa-alert--warning::before {
  background-color: $theme-color-warning;
}

.c-alert--neutral {
  background-color: $theme-color-base-lightest;
  border-left-color: $theme-color-base-lighter;
}

.c-alert--auto-width {
  // `display:table` lets an Alert remain a block-level element yet only be as
  // wide as its content requires. This is preferable to `inline-block` which
  // causes layout issues with inline adjacent siblings.
  display: table;
}
