@use "uswds-core" as *;

$button-stroke: inset 0 0 0 units($theme-button-stroke-width);

/* Set opacity:0 and move this input off screen. This is preferred over hidden or
 * display:none because screen readers will still consider it interactive this way */
.c-input-file__input {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.c-input-file__label {
  &:focus-within {
    @include focus-outline;
    outline-offset: units(0.5);
  }

  &--disabled {
    @include button-disabled;

    &.c-input-file__label--outline {
      background-color: color("transparent");
      box-shadow: $button-stroke color("disabled");
      color: color("disabled");

      &.usa-button--inverse {
        background-color: transparent;
        box-shadow: $button-stroke color("base");
        color: color("base");
      }

      &:hover,
      &.usa-button--hover,
      &:active,
      &.usa-button--active,
      &:focus,
      &.usa-focus {
        background-color: color("transparent");
        border: 0;
      }
    }

    &.c-input-file__label--unstyled {
      @include no-knockout-font-smoothing;
      background-color: transparent;
      box-shadow: none;
      color: color("disabled");
      text-decoration: underline;
    }
  }
}
