@use "uswds-utilities" as *;

.c-amendment-form {
  legend {
    @extend .font-heading-xs;
  }

  // TODO (CP-697): May change how spacing is applied to form components
  fieldset:first-child legend {
    @extend .margin-top-0;
  }
}

table .c-amendment-form {
  max-width: fit-content;
}
