/*
--------------------------------------------------------------------------------
U.S. Web Design System - Theme settings
--------------------------------------------------------------------------------
This file overrides the default USWDS theme tokens so that the USWDS appears
more like the Mass.gov design system (Mayflower).

Read more about settings and USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
--------------------------------------------------------------------------------
*/

@use "sass:math";
@use "~@massds/mayflower-assets/scss/00-base/variables" as massds_vars;
@use "~@massds/mayflower-assets/scss/00-base/_color-tokens.scss" as
  massds_colors;
@use "~@massds/mayflower-assets/scss/00-base/_fonts.scss" as massds_fonts;

@use "uswds-core" with (
  // ================================================
  // General settings
  // ================================================
  // Disable scarey but mostly irrelevant warnings:
  $theme-show-compile-warnings: false,
  $theme-show-notifications: false,
  // Point USWDS to our copy of its fonts and icon assets:
  $theme-font-path: "/fonts",
  $theme-image-path: "/img",

  // ================================================
  // Theme palette colors
  // https://designsystem.digital.gov/documentation/settings/#color
  // ================================================
  // Base colors
  $theme-color-base-lightest: massds_colors.$c-gray-lightest,
  $theme-color-base-lighter: massds_colors.$c-gray-light,
  $theme-color-base: massds_colors.$c-gray,
  $theme-color-base-dark: massds_colors.$c-gray-dark,
  $theme-color-base-ink: massds_colors.$c-black,

  // Primary colors
  $theme-color-primary-lighter: massds_colors.$c-bay-blue-lightest,
  $theme-color-primary-light: massds_colors.$c-bay-blue-lighter,
  $theme-color-primary: massds_colors.$c-bay-blue,
  $theme-color-primary-dark: massds_colors.$c-bay-blue-darker,
  $theme-color-primary-darker: massds_colors.$c-bay-blue-darkest,

  // Secondary colors
  $theme-color-secondary-lighter: massds_colors.$c-berkshires-green-lightest,
  $theme-color-secondary-light: massds_colors.$c-berkshires-green-lighter,
  $theme-color-secondary: massds_colors.$c-berkshires-green,
  $theme-color-secondary-dark: massds_colors.$c-berkshires-green-darker,
  $theme-color-secondary-darker: massds_colors.$c-berkshires-green-darkest,

  // ================================================
  // State palette colors
  // https://designsystem.digital.gov/documentation/settings/#color
  // ================================================
  // Enable support for the state colors in all color-related utility classes (i.e .bg-success)
  $global-color-palettes: ("palette-color-default", "palette-color-state"),
  // Error colors
  $theme-color-error-lighter: massds_colors.$c-error-red-lightest,
  $theme-color-error-light: massds_colors.$c-error-red-lighter,
  $theme-color-error: massds_colors.$c-error-red,
  $theme-color-error-dark: darken(massds_colors.$c-error-red, 5%),
  $theme-color-error-darker: massds_colors.$c-independence-cranberry-20-tint,

  // Warning colors
  $theme-color-warning-lighter: massds_colors.$c-duckling-yellow-lightest,
  $theme-color-warning-light: massds_colors.$c-duckling-yellow-lighter,
  $theme-color-warning: massds_colors.$c-duckling-yellow,

  // Success colors
  $theme-color-success-lighter: massds_colors.$c-berkshires-green-90-tint,
  $theme-color-success-light: massds_colors.$c-berkshires-green-lighter,
  $theme-color-success: massds_colors.$c-berkshires-green,
  $theme-color-success-dark: massds_colors.$c-berkshires-green-darker,
  $theme-color-success-darker: massds_colors.$c-berkshires-green-darkest,

  // Info colors
  $theme-color-info-lighter: massds_colors.$c-bay-blue-lightest,
  $theme-color-info-light: massds_colors.$c-bay-blue-lighter,
  $theme-color-info: massds_colors.$c-bay-blue,
  $theme-color-info-dark: massds_colors.$c-bay-blue-darker,
  $theme-color-info-darker: massds_colors.$c-bay-blue-darkest,

  // ================================================
  // Utility classes
  // https://designsystem.digital.gov/documentation/settings/#utilities
  // ================================================
  // Output utility styles with !important. This means utilities will always
  // override existing component styles, regardless of the component's CSS specificity,
  // which more closely aligns with people's mental models of utility classes.
  $utilities-use-important: true,

  // Enable more utility class modifiers
  $color-settings:
    (
      output: true,
      responsive: false,
      active: true,
      focus: false,
      hover: true,
      visited: false
    ),

  $text-align-settings: (output: true, responsive: true),
  $background-color-manual-values: ("transparent": transparent),
  // ================================================
  // Typography
  // https://designsystem.digital.gov/documentation/settings/#typography
  // ================================================
  $theme-typeface-tokens:
    (
      noto: (
        display-name: "'Noto Sans VF', 'Noto Sans'",
        cap-height: 357px,
      )
    ),

  // Use Noto everywhere
  $theme-font-type-sans: "noto",
  $theme-font-sans-custom-stack: "Helvetica, sans-serif",

  $theme-font-role-heading: "sans",
  $theme-font-role-alt: "sans",

  // Set font weights to match Mayflower's, which are unconventional.
  // Browsers default bold to 700, but that looks super heavy when
  // applied to the Mayflower typeface.
  $theme-font-weight-medium: 500,
  $theme-font-weight-bold: 550,

  // Set base font size to match Mayflower's so type sizes in components
  // like the header match what is displayed on Mass.gov
  $theme-respect-user-font-size: false,
  $theme-root-font-size: math.div(16px, 1.2),
  // Customize sizes used for type size tokens
  // https://designsystem.digital.gov/design-tokens/typesetting/font-size/
  $theme-type-scale-lg: 11,
  $theme-type-scale-md: 9,
  $theme-type-scale-sm: 7,
  $theme-type-scale-xs: 5,

  // Apply default type styles (family, size, line height) to the body element
  $theme-style-body-element: true,
  // Apply global styling to links, otherwise you have to apply .usa-link to every link
  $theme-global-link-styles: true,
  // Apply global <p> styling
  $theme-global-paragraph-styles: true,

  // Customize default body text size
  $theme-body-font-size: "xs",
  $theme-text-measure: 6,

  // Customize lead text styles
  $theme-lead-font-size: "sm",
  $theme-lead-line-height: 5,
  $theme-lead-measure: 6,

  // Customize heading type styles
  $theme-h1-font-size: "lg",
  $theme-h2-font-size: "md",
  $theme-h3-font-size: "sm",
  $theme-h4-font-size: "xs",
  $theme-h5-font-size: "2xs",

  // ================================================
  // Components
  // ================================================
  // Accordion
  $theme-accordion-border-width: 2px,

  // Button
  $theme-button-border-radius: 0,

  // Form and input
  $theme-checkbox-border-radius: 0,

  // Grid
  $theme-grid-container-max-width: massds_vars.$max-width,
  $theme-site-margins-breakpoint: "tablet",
  // Use USWDS spacing unit values that roughly match Mayflower's margin values (40px, 20px)
  $theme-site-margins-width: 5,
  $theme-site-margins-mobile-width: 2.5,

  // Table
  $theme-table-border-color: "base-lighter",
  $theme-table-header-background-color: "base-lightest",

  // Step Indicator
  $theme-step-indicator-text-pending-color: "base",
  $theme-step-indicator-segment-color-current: "primary",
  $theme-step-indicator-segment-color-complete: "secondary"
);
