@use "uswds-utilities" as *;

.row-button {
  .usa-checkbox__input + [class*="__label"]::before {
    @extend .radius-md;
    box-shadow: none;
    border: 2px solid $theme-color-error;
  }
  .usa-checkbox__input:checked + [class*="__label"]::before {
    background-color: $theme-color-error;
  }
  .delete {
    color: $theme-color-error;
  }
  &:disabled {
    text-decoration: none;
  }
  font-weight: $theme-font-weight-bold;
  text-decoration: none;
}
