@use "uswds-core" as *;

// https://github.com/CMSgov/design-system/blob/master/packages/docs/src/styles/base/_details.scss
.c-details {
  summary {
    display: block;
    cursor: pointer;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::before {
    content: "";
    /* Disable text underlining for this content */
    display: inline-block;
    background: url("#{$theme-image-path}/arrow-right-primary.svg") no-repeat
      center center;
    background-size: units(1.5) units(1.5);
    width: units(1.5);
    height: units(1.5);
    padding-right: units(1);
  }

  &[open] > summary::before {
    background-image: url("#{$theme-image-path}/arrow-down-primary.svg");
  }
}
